import React, { useEffect, useState } from "react";
import { Button, Card, Container, Stack } from "react-bootstrap";
import BudgetTitle from "../components/BudgetTitle";
import CategoryCard from "../components/CategoryCard";
import BB_Nav from "../components/Navbar";
import { DefaultBudgetURL, SignInURL } from "../components/Vars";
import { CreateBudgetURL } from "../components/Vars";
import { EditBudgetURL } from "../components/Vars";
import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { currencyFormatter } from "../components/Utils";
Chart.register(ChartDataLabels);


function DefaultBudget() {
    const loginURL = SignInURL
    const [defaultBudget, setDefaultBudget] = useState(null)
    const CreateBudgetPage = CreateBudgetURL
    const EditBudgetLink = EditBudgetURL

    window.localStorage.setItem('DefaultBudget', null);
    window.localStorage.setItem('EMAIL', null);
    window.localStorage.setItem('History', null);

    // if access token works... great!
    // if access token doesn't work, try refresh token
    // if refresh token doesn't work, redirect to sign-in

    function authorizationCodeAuth() {
        console.log('checking for auth code in search params');
        let params = new URLSearchParams(document.location.search);
        let code = params.get("code"); 
        console.log('code', code);

        if (code) {
            // make API Call to get auth token
            var myHeaders = new Headers();
            // myHeaders.append("Authorization", `Bearer ${Token}`);
            // myHeaders.append("BudgetID", `${DefaultBudgetID}`)
            // myHeaders.append("Access-Control-Allow-Origin", '*')
    
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
                mode: 'cors'
            };
            fetch(`https://82u01p1v58.execute-api.us-east-1.amazonaws.com/Prod/auth?code=${code}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log('auth code returned', result);
                const expired = '{"message":"The incoming token has expired"}';
                const Unauthorized = '{"message":"Unauthorized"}';
                const invalidGrant = '{"error":"invalid_grant"}';
                const clientUnauthorized = '{error: "unauthorized_client"}';
                if ((result) && (JSON.stringify(result) === expired || JSON.stringify(result) === Unauthorized || JSON.stringify(result) == invalidGrant || JSON.stringify(result) == clientUnauthorized)) {
                    // Try authCode
                    console.log('Auth code failed');
                    // authorizationCodeAuth();
                    console.log("redirect to sign-in")
                    window.location.replace(loginURL);
                    // setDefaultBudget(message => {
                    //     return JSON.stringify(result)
                    // })
                }
                else {
                    console.log('Auth Code Success!!');
                    console.log('access_token', result.access_token);
                    console.log('refresh_token', result.refresh_token);
                    // Store Access Token
                    localStorage.setItem('BB_USER_TOKEN', JSON.stringify(result.access_token));
                    // Store Refresh Token
                    localStorage.setItem('BB_refresh', JSON.stringify(result.refresh_token));
                    
                    // try again
                    getDefaultBudget(result.access_token);
                }

            })
            .catch(error => {
                console.log('error', error)
            });
        }
        else {
            console.log('no code in url, redirecting');
            window.location.replace(loginURL);
        }
    }

    function refreshAccessToken() {
        // get refresh token from local storage
        // make api call to authenticate user lambda
        console.log('refreshing access');
        let refresh = localStorage.getItem('BB_refresh') ? JSON.parse(localStorage.getItem('BB_refresh')) : false; 
        console.log('refresh', refresh);

        if (refresh) {
            // make API Call to get auth token
            var myHeaders = new Headers();
            // myHeaders.append("Authorization", `Bearer ${Token}`);
            // myHeaders.append("BudgetID", `${DefaultBudgetID}`)
            // myHeaders.append("Access-Control-Allow-Origin", '*')
    
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
                mode: 'cors'
            };
            fetch(`https://82u01p1v58.execute-api.us-east-1.amazonaws.com/Prod/auth?refresh=${refresh}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log('refresh call returned', result);
                const expired = '{"message":"The incoming token has expired"}';
                const Unauthorized = '{"message":"Unauthorized"}';
                const invalidGrant = '{"error":"invalid_grant"}';
                if (JSON.stringify(result) === expired || JSON.stringify(result) === Unauthorized || JSON.stringify(result) == invalidGrant) {
                    // Try authCode
                    console.log('trying authorization Code instead of refreshing')
                    authorizationCodeAuth();
                    // console.log("redirect to sign-in")
                    // window.location.replace(loginURL);
                    // setDefaultBudget(message => {
                    //     return JSON.stringify(result)
                    // })
                }
                else {
                    console.log('Refresh Success!!');
                    console.log('access_token', result.access_token);
                    // Store Access Token
                    localStorage.setItem('BB_USER_TOKEN', JSON.stringify(result.access_token));
                    
                    // try again
                    getDefaultBudget(result.access_token);
                }
            })
            .catch(error => {
                console.log('error', error);
                authorizationCodeAuth();
            });
        }
        else {
            console.log('no refresh token found.');
            authorizationCodeAuth();
        }
    }

    useEffect(() => {
        



        // const clientId = 'YOUR_CLIENT_ID';
        // const clientSecret = 'YOUR_CLIENT_SECRET';
        // const refreshToken = 'YOUR_REFRESH_TOKEN';

        // const tokenEndpoint = 'https://YOUR_COGNITO_DOMAIN/oauth2/token';

        // const refreshAccessToken = async () => {
        //     const params = new URLSearchParams();
        //     params.append('grant_type', 'refresh_token');
        //     params.append('client_id', clientId);
        //     params.append('refresh_token', refreshToken);

        //     const headers = new Headers();
        //     headers.append('Content-Type', 'application/x-www-form-urlencoded');
        //     headers.append('Authorization', 'Basic ' + Buffer.from(`${clientId}:${clientSecret}`));

        //     try {
        //         const response = await fetch(tokenEndpoint, {
        //             method: 'POST',
        //             headers: headers,
        //             body: params
        //         });

        //         if (!response.ok) {
        //             throw new Error('Network response was not ok ' + response.statusText);
        //         }

        //         const data = await response.json();
        //         const { access_token, id_token, refresh_token } = data;
        //         console.log('Access Token:', access_token);
        //         console.log('ID Token:', id_token);
        //         console.log('Refresh Token:', refresh_token);
        //     } catch (error) {
        //         console.error('Error refreshing tokens:', error);
        //     }
        // };

        // refreshAccessToken();

    }, []);

    // window.localStorage.setItem("BB_USER_TOKEN", "eyJraWQiOiJSODZ6ZUpINEl1U1RHeUpNUTI2XC82azBTSFYwakRmVFlqTWJkczdycmFrbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0YzA3OGJkMy0wYjZmLTRlYzQtOTZhNi1mNDM0MTlmMWUyOTAiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9QSkdGOHgxaDUiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiIxazZsZDltODlpa2ZwNG5wdHZzaGo1YXFkIiwib3JpZ2luX2p0aSI6IjhjOGExMWNiLTM3MzAtNDQ1OS1hNmZlLWFhNGNmY2E1MWZkYiIsImV2ZW50X2lkIjoiNDg4MWU5NTUtMmYzZS00NDM5LWJkYjgtZGU0MmVkZDhhMzg0IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTczNTQ0NDkwMSwiZXhwIjoxNzM1NTMxMzAxLCJpYXQiOjE3MzU0NDQ5MDEsImp0aSI6IjlmZDQxOTc0LWZmZDAtNGMyNS1hMjFlLWMyOTIxODE1M2E5MyIsInVzZXJuYW1lIjoiNGMwNzhiZDMtMGI2Zi00ZWM0LTk2YTYtZjQzNDE5ZjFlMjkwIn0.ZfGofSqQhNKcYMGcovVB4tal4NJB82IZ5Ytkfk7VJtcFFZvIkU2EpSN7IiJdOZ6XSXpwmYCFHE3Gb2m_YMiHTSm2GT7gSQGGMiqXhb710bihjUNVBlLVdB_FG9Gx1RkGd_khxhONHEuWi4Os1J86QDlHxO69iXb8IrHG66B-Euj7feConVKmNwrdAcweBLQl8mGh7ROqWWk7t9P17zz_2igRVVethAeKKxPySG-dPz7oLx0x2KN8xJ-md7wUbrIYYXDFX5167hjA7bTysk8XR6nAoIMkpNTw3NHdKkzbc94_FTEA2afRSDc5O3hCYjKnwe1kEcAlQY4_LVPGZsUEZg");

    function getDefaultBudget(Token) {
        console.log('get budgets token:', Token);
        // make API Call
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${Token}`);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
        mode: 'cors'
        };
        // console.log('Getting Budgets (API Call)');
        fetch("https://82u01p1v58.execute-api.us-east-1.amazonaws.com/Prod/budgets", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log("Get Budgets res: ", result)
            const expired = '{"message":"The incoming token has expired"}'
            const Unauthorized = '{"message":"Unauthorized"}'
            const invalidGrant = '{"error":"invalid_grant"}'
            if (JSON.stringify(result) === expired || JSON.stringify(result) === Unauthorized ) {
                // Try RefreshToken
                console.log('get budgets failed');
                refreshAccessToken();
                // console.log("redirect to sign-in")
                // window.location.replace(loginURL);
                // setDefaultBudget(message => {
                //     return JSON.stringify(result)
                // })
            }
            else if (JSON.stringify(result) === '{"Budgets":[]}') {
                
                // console.log("User has no budgets", result)
                // console.log("redirect to Create Budget page")
                window.location.replace(CreateBudgetPage);
                // setDefaultBudget(message => {
                //     return JSON.stringify(result)
                // })
            }
            else if (JSON.stringify(result) == `{"message":"Internal server error"}`) {
                
                // console.log("an error occured", result)
                setDefaultBudget(message => {
                    return JSON.stringify({error: "error"})
                })
            }
            else {
                // console.log("Budgets: ", result);
                // console.log("string", JSON.stringify(result));
                // get default budget ID
                var DefaultBudgetID = null;
                var DBudget = null;
                result.Budgets.forEach(Budget => {
                    if (Budget.IsDefault === true ) {
                        DefaultBudgetID = Budget.BudgetID;
                        DBudget = Budget
                    }
                });
                // setDefaultBudget(MyBudget => {
                //     return DBudget
                // });
                
                // ------------------
                // get categories
                // ------------------
                // make API Call
                // console.log(DefaultBudgetID)
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${Token}`);
                myHeaders.append("BudgetID", `${DefaultBudgetID}`)
                // myHeaders.append("Access-Control-Allow-Origin", '*')

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
                mode: 'cors'
                };
                // console.log('Getting Categories (API Call)');
                fetch(`https://82u01p1v58.execute-api.us-east-1.amazonaws.com/Prod/categories?BudgetID=${DefaultBudgetID}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    // console.log(result)
                    if (result === '{"message":"The incoming token has expired"}' || result === '{"message":"Unauthorized"}') {
                        // console.log("redirect to sign-in")
                        window.location.replace(loginURL);
                        setDefaultBudget(message => {
                            return JSON.stringify(result)
                        })
                    }
                    else if (JSON.stringify(result) === '{}') {
                        // console.log("Default Budget has no categories", JSON.stringify(result))
                        setDefaultBudget(MyBudget => {
                            return JSON.stringify({...DBudget, ...result})
                        })

                    }
                    else {
                        // console.log("here's your categories!", result);
                        // add categories to state {...MyBudget, ...result}
                        let defaultBudget = {...DBudget, ...result};
                        /////////////////////
                        // Get Report Data
                        /////////////////////
                        // make API Call
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${Token}`);
                        myHeaders.append("BudgetID", `${DefaultBudgetID}`);
                        // myHeaders.append("Access-Control-Allow-Origin", '*')

                        var requestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow',
                        mode: 'cors'
                        };
                        // console.log('Getting Report Data (API Call)');
                        fetch(`https://82u01p1v58.execute-api.us-east-1.amazonaws.com/Prod/reportdata?BudgetID=${DefaultBudgetID}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            // console.log('ReportCallResponse', result);
                            window.localStorage.setItem('History', JSON.stringify(result));
                            // setReportData(() => JSON.stringify(result));
                            // console.log('updating State for setDefaultBudget');
                            setDefaultBudget(MyBudget => {
                                    return JSON.stringify({...defaultBudget, ...result})
                                });
                        });

                        // setDefaultBudget(MyBudget => {
                        //     return JSON.stringify({...DBudget, ...result})
                        // })
                    }

                })
                .catch(error => console.log('error', error));
                
                
                // /////////////////////
                // // Get Report Data - *** MOVE THIS INSIDE GET CATEGORIES REQUEST ***
                // /////////////////////
                // // make API Call
                // var myHeaders = new Headers();
                // myHeaders.append("Authorization", `Bearer ${Token}`);
                // myHeaders.append("BudgetID", `${DefaultBudgetID}`)
                // // myHeaders.append("Access-Control-Allow-Origin", '*')

                // var requestOptions = {
                // method: 'GET',
                // headers: myHeaders,
                // redirect: 'follow',
                // mode: 'cors'
                // };

                // fetch(`https://82u01p1v58.execute-api.us-east-1.amazonaws.com/Prod/reportdata?BudgetID=${DefaultBudgetID}`, requestOptions)
                // .then(response => response.json())
                // .then(result => {
                //     console.log('ReportCallResponse', result);
                //     window.localStorage.setItem('History', JSON.stringify(result));
                //     // setReportData(() => JSON.stringify(result));
                    
                // });
                
            }

        }).catch(error => console.log('error', error));
        
        
    }
        
    // // Get token from URL if exists
    // var currentURL = window.location;
    // var token = currentURL.hash;
    // var accessToken = new URLSearchParams(token).get('access_token');
    // // console.log(accessToken);
    // // window.localStorage.setItem('BB_USER_TOKEN', accessToken);    
    // // if no token in URL
    // if (accessToken === null) {
    //     // console.log('Token Not in url')
    //     // get stored token
    //     var accessToken = window.localStorage.getItem('BB_USER_TOKEN')
    //     // console.log('stored Token:', accessToken)
        
    //     getDefaultBudget(accessToken)
    // }
    // // if there is a token in the url
    // else {
    //     // console.log('Token in URL')
    //     window.localStorage.setItem('BB_USER_TOKEN', accessToken);
    //     getDefaultBudget(accessToken)

    // }

    // Start here
    let accessToken = JSON.parse(window.localStorage.getItem('BB_USER_TOKEN'));
    if (accessToken) {
        console.log('stored token', accessToken);
        getDefaultBudget(accessToken);
    }
    else {
        refreshAccessToken();
    }

    // Check weather we need to reset the budget because of a new month
    if (defaultBudget) {
        // get today's month and compare it to the last stored month.
        let parsedBudget = JSON.parse(defaultBudget);
        let today = new Date();
        let month = today.getMonth() + 1;
        console.log("Today's Month:", month);
        console.log('defaultBudget', parsedBudget.storedMonth);
        console.log(`storedMonth: ${parsedBudget.storedMonth} ${typeof parsedBudget.storedMonth}, today's month: ${month} ${typeof toString(month)}`);
        if (parsedBudget.storedMonth) {
            // console.log('parsedBudget.storedMonth', parsedBudget.storedMonth);
            console.log('not equal', month != parsedBudget.storedMonth);
            console.log('equal', month == parsedBudget.storedMonth);
            if (month != parsedBudget.storedMonth) {
                console.log("Different month detected", `storedMonth: ${parsedBudget.storedMonth} ${typeof parsedBudget.storedMonth}, today's month: ${month} ${typeof month}`);

                var Token = JSON.parse(window.localStorage.getItem('BB_USER_TOKEN'));
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${Token}`);
                // myHeaders.append("BudgetID", `${parsedBudget.BudgetID}`);
                myHeaders.append("Content-Type", `application/json`);
                // myHeaders.append("Access-Control-Allow-Origin", '*')

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                mode: 'cors',
                };

                fetch(`https://82u01p1v58.execute-api.us-east-1.amazonaws.com/Prod/budgets/clear?month=${month}&BudgetID=${parsedBudget.BudgetID}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log('clear budget result:', result)
                    const expired = '{"message":"The incoming token has expired"}'
                    const Unauthorized = '{"message":"Unauthorized"}'
                    if (JSON.stringify(result) === expired || JSON.stringify(result) === Unauthorized) {
                        // console.log("redirect to sign-in")
                        // window.location.replace(loginURL);
                        console.log('clear budget failure')
                    }
                    else {
                        console.log("Success: ", result);
                        getDefaultBudget(Token);
                        // console.log("Body sent: ", JSON.stringify(body));
                        // if (result == "Update-Budget-Lambda completed successfully"){
                        //     window.location.replace(DefaultBudgetURL);
                        // }
                        // else {
                        //     window.location.replace(DefaultBudgetURL);
                        // }
                    }
                }).catch(error => { 
                    console.log('error', error);
                    // window.location.replace(DefaultBudgetURL);
                });
            }
            else {
                console.log('they are the same');
            }
        }
    }
    

    function renderbudget(BudgetResponse) {
        if (BudgetResponse === null) {
            return "Loading..."
        }
        else if (defaultBudget === '{"Categories":{}}') {
            console.log("Empty Budget: ", defaultBudget)
            // window.location.replace(CreateBudgetPage);
        }
        else if (BudgetResponse == `{"error":"error"}` || defaultBudget == `{"error":"error"}`) {
            return (
                <>
                    <h5>Something went wrong</h5>
                </>
            )
        }
        else {
            // console.log(BudgetResponse)
            let Budget = JSON.parse(defaultBudget);
            window.localStorage.setItem('DefaultBudget', defaultBudget);
            Budget.Categories.sort((a, b) => {
                return Number(a.CategoryPositionID) - Number(b.CategoryPositionID)
            });
            return(
                <Container>
                        {/* <p>{BudgetResponse}</p> */}
                        <Stack>
                            <BudgetTitle name={Budget.BudgetName} amount={Number(Budget.BudgetAmountUsed)} max={Number(Budget.BudgetAmountTotal)}/>
                        </Stack>
                        <hr style={{color: 'black', width: '100%', border: '1px solid black'}} />
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                            gap: "1rem",
                            alignItems: "flex-start"
                        }}>
                            {Budget.Categories.map((Category, index) => {
                                // console.log(Category)
                                return(
                                    <CategoryCard key={index} index={index} setDefaultBudget={setDefaultBudget} cat={Category} categoryid={Category.CategoryID} budget={Budget} name={Category.CategoryName} amount={Number(Category.CategoryAmountUsed)} max={Number(Category.CategoryAmountTotal)}/>
                                )
                            })}
                        </div>
                        <Stack>
                            <Container className="mt-3">
                                <Button variant="secondary" href={EditBudgetLink}>Edit Budget</Button>
                            </Container>
                        </Stack>
                </Container>

            )
        }
    }

    function RenderReportData(BudgetResponse) {
        if (BudgetResponse === null) {
            return "Loading Reports..."
        }
        else if (BudgetResponse == `{"error":"error"}` || defaultBudget == `{"error":"error"}`) {
            return (
                <>
                    <h5>Something went wrong</h5>
                </>
            )
        }
        else {
            // Move Get Report Data API Call to the Get Categories Call! THis should reduce rerenders. 
            // 1 bar chart for each category. Each chart will display amount on Y and Month on X. with Name of the Category on Top of the chart
            // one overall budget chart that adds up all the category expenses.
            let budget = JSON.parse(defaultBudget);
            
            budget.CurrentCategories.sort((a, b) => {
                return Number(a.CategoryPositionID) - Number(b.CategoryPositionID)
            });
            
            // add overall budget Category
            let overallCat = {
                CategoryID: 'budget',
                CategoryName: budget.BudgetName,
                CategoryAmountTotal: budget.BudgetAmountTotal,
                CategoryAmountUsed: budget.BudgetAmountUsed
            }

            budget.CurrentCategories.unshift(overallCat);

            // add related history items for the overall budget category
            // for each hist item in the same month, add up their amount used and create a new history item with the amount and the CategoryID of 'budget'
            
            // get list of available months
            let availableMonths = [];
            budget.CategoryHistory.forEach(hist => {
                let month = new Date(hist.Timestamp.slice(0, 10)).toDateString().slice(4, 7);
                if ( !(availableMonths.includes(month)) ) {
                    availableMonths.push(month);
                }
            });
            
            // foreach available month, walk through the hist items and add up their amount used. Create new item with acquired info. 
            let overallHistItems = []
            availableMonths.forEach( month => {
                let monthTotal = 0;
                let TS;
                budget.CategoryHistory.forEach(histItem => {
                    let histMonth = new Date(histItem.Timestamp.slice(0, 10)).toDateString().slice(4, 7);
                    if ( histMonth == month ) {
                        monthTotal += parseFloat(histItem.CategoryAmountUsed);
                        TS = histItem.Timestamp;
                    }
                });
                let newHistItem = {
                    CategoryID: "budget",
                    CategoryAmountUsed: monthTotal,
                    Timestamp: TS
                }
                overallHistItems.push(newHistItem);
            });
            
            // add overall hist items to the Category History array
            budget.CategoryHistory = budget.CategoryHistory.concat(overallHistItems);

            let states = budget.CurrentCategories.map(cat => {
                let relatedHistItems = budget.CategoryHistory.filter(histItem => histItem.CategoryID == cat.CategoryID);
                // relatedHistItems = relatedHistItems.sort(() => )
                let newCat = {...cat, Timestamp: new Date().toISOString() }
                relatedHistItems.push(newCat);

                
                //transform data to 
                let newState = {
                    labels: relatedHistItems.map(item => new Date(item.Timestamp.slice(0, 10)).toDateString().slice(4, 7) ),
                    datasets: [
                        {
                            label: `${cat.CategoryName} | Max: ${currencyFormatter.format(cat.CategoryAmountTotal)}`,
                            data: relatedHistItems.map(item => Math.round(parseFloat(item.CategoryAmountUsed))),
                            backgroundColor: "green",
                            borderColor: "rgba(0, 0, 0, 1)",
                            borderWidth: 2
                        }
                    ]
                    
                }

                return newState
            })
            // console.log('states', states);

            // const state = {
            //     labels: [],
            //     datasets: [
            //         {
            //             label: "CategoryName",
            //             backgroundColor: "rgba(75, 192, 192, 1)",
            //             borderColor: "rgba(0, 0, 0, 1)",
            //             borderWidth: 2,
            //             data: ["array of numbers corresponding to "]
            //         }
            //     ]
            // }

            return (
                <Container style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 3fr))",
                    gap: "1rem",
                    alignItems: "flex-start"
                }}>
                    {states.map((state, i) => {
                        let options = {
                            maintainAspectRation: false,
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                title: {
                                    text: state.datasets[0].label,
                                    display: true,
                                    font: {
                                        size: 16
                                    }
                                },
                                datalabels: {
                                    color: 'white',
                                    font: {
                                        size: 14
                                    }
                                },
                                id: 'horizontalArbitraryLine',
                                // beforeDraw(chart, args, options) {
                                //     const { ctx, chartArea: { top, right, bottom, left, width, height }, scales: {x, y} } = chart;
                                //     ctx.save();
                                //     ctx.strokeStyle = 'blue';
                                //     ctx.strokeRect(x0, y0, x1, y1);
                                // }
                            }
                        }
                        return (
                            <div key={i}>
                                <div style={{  backgroundColor: 'white', paddingRight: '10px', paddingLeft: '5px',  paddingBottom: '10px', borderRadius: '10px' }} >
                                    {/* <Card.Body> */}
                                        <Bar data={state} options={options} />
                                    {/* </Card.Body> */}
                                        
                                </div>
                                {i == 0 && <hr/>}
                            </div>
                            
                        )
                    })}
                </Container>
            )
        }
        
    }

    return(
        <Container>
            <Container>
                <BB_Nav/>
            </Container>
            {renderbudget(defaultBudget)}
            <hr style={{
                    color: 'black'
                }} />
            {defaultBudget && <h2 className="mb-0">Reports</h2>}
            {defaultBudget && <p>Last 12 months</p>}
            {RenderReportData(defaultBudget)}
        </Container>
    )
}

export default DefaultBudget;